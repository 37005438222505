@import "../mixins";

.box-card {
  &__image {
    width: 83px;
    height: 83px;
    border-radius: 24px;
    box-shadow: 0 12px 56px rgba(53, 64, 87, 0.08);
    background: rgba(39, 90, 219, 0.05);
    justify-content: center;
    margin-bottom: 24px;
  }

  &__title {
    color: #303547;
    font-size: 24px;
    margin-bottom: 15px;
  }

  &__text {
    color: #68727a;
    font-family: "Roboto-Regular", sans-serif;
  }

  @media (max-width: 1480px) {
    &__title {
      font-size: 19px;
    }

    &__text {
      font-size: 15px;
      line-height: 1.4;
    }
  }

  @media screen and (max-width: 1200px) {
    &__title {
      margin-bottom: 10px;
    }

    &__image {
      width: 60px;
      height: 60px;

      img {
        width: 38px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    &__title {
      font-size: 17px;
    }

    &__text {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    &__image {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.box-wrap {
  .box-card-wrap {
    width: calc(100% - 485px);

    .box-card {
      flex: 0 0 31.8%;
      max-width: 31.8%;

      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 1600px) {
      width: calc(100% - 410px);
    }

    @media screen and (max-width: 1200px) {
      width: calc(100% - 300px);
    }

    @media (max-width: 1024px) {
      width: 100%;
      margin-top: 30px;
    }

    @media screen and (max-width: 768px) {
      .box-card {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 25px;
        text-align: center;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  figure {
    figcaption {
      font-size: 14px;
      margin-top: 10px;
      text-align: center;
      margin-bottom: 0;
      color: #50575c;
    }

    @media screen and (max-width: 1600px) {
      width: 360px;

      img {
        width: 100%;
      }
    }

    @media screen and (max-width: 1200px) {
      width: 250px;
    }

    @media screen and (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.section-page {
  padding: 60px 0;

  h2 {
    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .big-size {
    font-size: 36px;

    @media (max-width: 1660px) {
      font-size: 30px;
    }

    @media (max-width: 1360px) {
      font-size: 28px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      text-align: center;
    }
  }

  @media (max-width: 1360px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 768px) {
    figure {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
