@import '../mixins';

.footer {
  background: #13299f;
  padding: 40px 0 20px 0;
  overflow: hidden;

  a,
  h3,
  h4 {
    color: #fff;
  }

  .copy {
    font-size: 13px;
  }

  h4 {
    font-size: 16px;
  }

  .logo {
    margin-bottom: 17px;
    font-size: 24px;
    display: block;

    @media (max-width: 1200px) {
      margin-bottom: 0;
      margin-right: 25px;
    }
  }

  &-group {
    h4 {
      margin-bottom: 16px;
    }

    &-width {
      color: #fff;
      width: 228px;

      @media (max-width: 1360px) {
        width: 150px;
      }

      @media (max-width: 1200px) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 25px;
      }

      @media (max-width: 480px) {
        .copy {
          width: 100%;
          padding-top: 8px;
        }
      }
    }
  }

  &-menu {
    .foot-column {
      padding-right: 100px;

      &:last-child {
        padding-right: 0;
      }

      @media (max-width: 1640px) {
        padding-right: 70px;
      }

      @media screen and (max-width: 1360px) {
        padding-right: 40px;
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 23px;
      }

      @media (max-width: 576px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-bottom: 24px;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  &-top {
    justify-content: space-between;
  }

  &-bottom {
    justify-content: space-between;
    padding-top: 33px;
    font-size: 12px;

    .footer-group {
      a {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding-top: 20px;

      .footer-group {
        width: 100%;
        padding-bottom: 10px;

        a {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 1169px) {
      bottom: 0;
      top: unset;

      svg {
        width: auto;
      }
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  @media screen and (max-width: 1200px) {
    padding-top: 25px;
  }
}

.foot-column {
  h3 {
    margin-bottom: 20px;
  }

  ul {
    li {
      margin-bottom: 10px;
      font-size: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1640px) {
    h3 {
      font-size: 18px;
    }

    ul li {
      font-size: 14px;
    }
  }

  @media (max-width: 1360px) {
    h3 {
      font-size: 16px;
      margin-bottom: 10px;
    }

    ul li {
      font-size: 13px;
    }
  }
}

.social {
  display: flex;

  .social-item {
    padding: 0 8px;

    a {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;

      &:hover {
        background: #275adb;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
