//.suggestions-wrapper {
//  position: absolute;
//  z-index: 99999;
//  width: 100%;
//}

.suggestions-input {
  border: 1px solid #d2d8e4;
  border-radius: 4px;
  padding: 10px 16px;
  color: #3f4448;
  font-size: 13px;
  width: 100%;

  &:focus {
    border-color: var(--primary);
    box-shadow: none;
  }
}

.suggestions-container {
  position: relative;
}

.suggestions-suggestions {
  background-color: #fff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
}

/**
 * Контейнер для одной подсказки
 */

.suggestions-suggestion {
  padding: 6px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 13px;

  &:hover {
    background: #deebff;
  }
}

.suggestions-selected {
  background: #deebff;
}

.suggestions-hint {
  font-size: 13px;
}

.suggestions-highlighting {
  color: var(--primary);
  background: transparent;
}
