@import '../variables';

.card-employee {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;

  &__name {
    font-size: 18px;
    color: $dark;
    padding-bottom: 3px;
  }

  &__position {
    font-size: 14px;
  }

  &__img {
    img {
      width: 100%;
    }
  }

  &__content {
    padding-top: 16px;
  }

  @media screen and (max-width: 1200px) {
    &__name {
      font-size: 16px;
    }

    &__img {
      padding-bottom: 10px;
    }
  }

  @media screen and (max-width: 992px) {
    &__name {
      font-size: 15px;
    }

    &__position {
      font-size: 13px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 17px;

    &__name {
      font-size: 14px;
    }

    &__content {
      padding-top: 0;
    }

    &__img {
      padding-bottom: 6px;
    }
  }
}
