.preloader-bg {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(246, 249, 255, 0.8);

  .preloader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .preloader-content {
    position: relative;

    p {
      color: var(--primary);
      font-size: 18px;
      text-align: center;
      margin-top: 15px;
    }
  }
}
