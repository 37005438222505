.list-characteristics {
  padding-bottom: 15px;

  &__title {
    font-size: 16px;
    color: #3b4459;

    &--min {
      font-size: 14px;
    }
  }

  &__content {
    font-size: 14px;

    &--pt {
      margin-top: 11px;
    }
  }

  &__link {
    margin-right: 15px;
  }

  .badge {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 1200px) {
    &__title {
      font-size: 15px;
    }

    &__content {
      font-size: 13px;

      &--pt {
        padding-top: 7px;
      }
    }
  }
}

.list-characteristics-wrap {
  .list-characteristics {
    border-bottom: 1px solid #e9ecf2;
    padding-bottom: 0;
    padding-top: 15px;

    &:last-child {
      border-bottom: none;
    }

    @media screen and (max-width: 992px) {
      padding-top: 11px;
    }
  }
}
