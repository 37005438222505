.card {
  flex: 0 0 30%;
  max-width: 30%;
  text-align: center;

  h3 {
    font-size: 18px;
    max-width: 260px;
    margin: 19px auto 15px;
    color: var(--primary);
  }

  p {
    margin-bottom: 0;
    font-size: 15px;
  }

  img {
    max-width: 100%;
  }

  @media (max-width: 1366px) {
    h3 {
      font-size: 16px;
    }
  }

  @media (max-width: 1199px) {
    flex: 0 0 49%;
    max-width: 49%;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 992px) {
    h3 {
      font-size: 15px;
      margin: 14px auto 9px;
    }

    p {
      font-size: 14px;
    }
  }

  @media (max-width: 766px) {
    flex: 0 0 100%;
    max-width: 100%;

    h3 {
      max-width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
