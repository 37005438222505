.custom-drop.react-dropdown-tree-select {
  width: 100%;

  .dropdown {
    width: 100%;

    .dropdown-trigger {
      border: 1px solid #d2d8e4;
      border-radius: 4px;
      width: 100%;
      padding: 4px 10px;
      min-height: 41px;
      display: flex;
      align-items: center;
    }
  }

  .dropdown .dropdown-trigger.arrow.top::after,
  .dropdown .dropdown-trigger.arrow.bottom::after {
    content: none;
  }

  label {
    font-size: 14px;
    color: #50575c;
    margin-bottom: 0;
    display: inline-flex;

    span {
      margin-left: 5px;
    }
  }

  .checkbox-item {
    width: 1.25rem;
    height: 1.25rem;
    opacity: 1;
    position: relative;
    cursor: pointer;
    padding: 0;
    border: none;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      transition: all 0.2s ease-in-out;
      background-color: #fff;
      border: 0.125rem solid #d2d8e4;
      border-radius: 0.25rem;
      text-align: center;
      line-height: 15px;
    }

    &:checked::before {
      content: url("data:image/svg+xml; utf8, <svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9 1L3.5 6.5L1 4' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
      background: #275adb;
      border: 0.125rem solid #275adb;
    }
  }

  .dropdown-content {
    height: 330px;
    overflow: auto;
    z-index: 9999;
    width: 100%;

    .node {
      padding-bottom: 7px;
      display: flex;
      align-items: center;
    }

    .toggle {
      font-style: initial;
      font-family: "Inter-SemiBold", sans-serif;
      width: 15px;
      display: inline-block;
    }
  }

  .tag {
    font-size: 13px;
    background: #f4f6fa;
    color: var(--primary);
    padding: 2px 5px;
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
  }

  .tag-item {
    margin: 0;

    .search {
      border: none;
      padding: 6px 0;

      &:focus {
        border: none;
      }
    }

    &:not(:last-child) {
      padding-bottom: 5px;
      padding-right: 5px;
    }

    &:last-child {
      width: 100%;
    }
  }

  .tag-remove {
    background: #99aac3;
    border-radius: 3px;
    border: none;
    color: #fff;
    width: 17px;
    height: 17px;
    line-height: 17px;
    padding: 0;
    font-size: 13px;
    margin-left: 3px;
  }
}
