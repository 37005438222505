@import "../variables";

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form {
  textarea {
    resize: none;
  }

  input,
  textarea {
    border: 1px solid #d2d8e4;
    border-radius: 4px;
    padding: 12px 16px;
    color: $dark;
    font-size: 13px;
    width: 100%;
    background: #fff;

    &:focus {
      border: 1px solid var(--primary);
      outline: none;
    }

    &:disabled {
      background: #d4d5d63b;
    }

    @media screen and (max-width: 992px) {
      padding: 10px 14px;
    }
  }

  .form-control-textarea {
    height: 150px;
  }

  .form__label {
    margin-bottom: 10px;
    line-height: 18px;
    color: #2d3033;
    font-family: "Inter-Medium", sans-serif;

    @media screen and (max-width: 1200px) {
      font-size: 15px;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  .form__item--custom {
    > label {
      margin-bottom: 0;
      display: block;

      > span {
        margin-bottom: 10px;
        display: block;
      }
    }

    .form__span {
      margin-bottom: 10px;
      display: block;
    }
  }

  &__item {
    position: relative;
    margin-bottom: 20px;
  }

  .form-text {
    font-size: 12px;
    color: #68727a;
    padding-top: 4px;
  }

  legend {
    font-size: 18px;
    color: #2d3033;
    margin-bottom: 20px;

    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  legend.legend-custom {
    margin-bottom: 10px;
    font-size: 16px;
  }

  button {
    letter-spacing: inherit;
  }

  .form__inline {
    .form__inline-item {
      flex: 1;
      position: relative;

      &:first-child {
        margin-right: 8px;
      }
    }
  }

  .item-hidden {
    margin-bottom: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s ease opacity;

    &--active {
      height: auto;
      margin-bottom: 20px;
      opacity: 1;
      visibility: visible;
    }
  }
}

.label-custom {
  cursor: pointer;
  display: flex;
  font-size: 14px;
  align-items: center;

  span {
    margin-left: 30px;

    @media screen and (max-width: 1200px) {
      font-size: 15px;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  a {
    position: relative;
  }
}

input[type=checkbox] {
  opacity: 0;
  position: absolute;
  height: 100%;
}

.label-custom::before {
  content: "";
  background-color: #fff;
  border: 2px solid #d2d8e4;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-custom::after {
  content: url("data:image/svg+xml; utf8, <svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9 1L3.5 6.5L1 4' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  background: var(--primary);
  transition: all 0.2s;
  border: 2px solid var(--primary);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"]:not(:checked) + .label-custom::after {
  opacity: 0;
  transform: scale(0);
}


/* Radio */

input[type="radio"] {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

.radio-custom {
  opacity: 0;
  position: absolute;
}

///* radio в резюме и вакансиях */
.radio-label {
  position: relative;
  color: #50575c;
  background: rgba(244, 246, 250, 0.5);
  border: 2px solid #d2d8e4;
  border-radius: 4px;
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    position: relative;
    padding-left: 35px;

    &::before {
      content: "";
      background: #fff;
      border: 0.0625rem solid hsla(0, 0%, 67.1%, 0.75);
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      cursor: pointer;
      text-align: center;
      transition: all 0.3s ease;
      position: absolute;
      left: 0;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 180px;
    height: 55px;
  }
}

input[type="radio"]:checked + .radio-label {
  background: #fff;
  border-color: var(--primary);

  span {
    &::before {
      background-color: var(--primary);
      box-shadow: inset 0 0 0 0.25rem #fff;
      border: 0.0625rem solid var(--primary);
    }
  }
}

/* switch */
.switch {
  position: relative;
  display: inline-flex;
  align-items: center;

  &__slider {
    width: 48px;
    height: 25px;
    position: relative;
    cursor: pointer;
    background-color: #dadce3;
    transition: 0.4s;
    border-radius: 16px;
    margin-right: 12px;

    &::before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      border-radius: 50%;
      left: 4px;
      bottom: 4px;
      background-color: #fff;
      transition: 0.4s;
    }
  }

  input:checked + .switch__slider {
    background-color: #4e8bff;
  }

  input:focus + .switch__slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .switch__slider::before {
    transform: translateX(25px);
  }

  @media screen and (max-width: 992px) {
    .switch__label {
      width: calc(100% - 60px);
      font-size: 15px;
    }
  }
}
