.hero-bg {
  width: 956px;
  height: 956px;
  border-radius: 50%;
  background: rgba(39, 90, 219, 0.7);
  position: absolute;
  right: -280px;
  top: -150px;
  z-index: -1;

  @media (max-width: 1480px) {
    right: -360px;
    width: 900px;
    height: 900px;
  }

  @media (max-width: 1360px) {
    right: -330px;
    width: 850px;
    height: 850px;
  }

  @media (max-width: 1199px) {
    width: 760px;
    height: 760px;
    top: 10px;
    right: -150px;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.form-search {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  position: relative;

  .filter {
    color: #68727a;
    border: 1px solid #d2d8e4;
    background: #fff;

    span {
      margin-right: 15px;
    }
  }

  .filter,
  .button {
    width: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button {
    border-radius: 0;

    span {
      margin-right: 15px;
    }
  }

  .main-input {
    padding: 16px 24px;
    border: 1px solid #d2d8e4;
    width: calc(100% - 270px);
    border-radius: 0;
  }

  @media screen and (max-width: 767px) {
    box-shadow: none;

    .main-input {
      width: calc(100% - 96px);
      padding: 12px 10px;
    }

    .filter,
    .button {
      width: 48px;

      span {
        display: none;
      }

      svg {
        width: 16px;
      }
    }
  }
}

.filter-content {
  position: absolute;
  padding: 17px 10px;
  width: 100%;
  background: #fff;
  border: 1px solid #d2d8e4;
  border-radius: 4px;
  margin-top: 5px;
  transition: 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  transform: translateY(80px);

  .flex-element {
    align-items: flex-end;
  }

  &--open {
    visibility: visible;
    opacity: 1;
    transform: translateY(60px);
    transition: 0.2s ease-in-out;
  }

  &__button {
    padding-bottom: 10px;

    button {
      color: #68727a;
      border: 1px solid #d2d8e4;
      background: #fff;
      padding: 5px 15px;

      &.active {
        background: var(--primary);
        color: #fff;
        border-color: (--primary);
      }
    }
  }

  .form__item {
    width: calc((100% / 3) - 20px);
    margin: 0 10px 8px 10px;

    .suggestions-input {
      width: 100%;
    }

    label,
    legend {
      font-size: 13px;
    }

    .form-control {
      padding-top: 12px;
      padding-bottom: 11px;
    }

    .css-2b097c-container,
    .react-dadata .react-dadata__input {
      width: 100%;
    }
  }

  @media screen and (max-width: 1480px) and (min-width: 1200px) {
    .form__item {
      width: calc((100% / 2) - 20px);
    }
  }

  @media screen and (max-width: 768px) {
    padding: 15px 5px;
    transform: translateY(70px);

    .form__item {
      width: calc((100% / 2) - 20px);
    }

    &--open {
      transform: translateY(50px);
    }
  }

  @media screen and (max-width: 480px) {
    .form__item {
      width: 100%;
    }
  }
}

.home-intro {
  height: 739px;

  &__container {
    max-width: 1920px;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 53%;
    height: 100%;
    margin-left: 3.5%;
  }

  &__area {
    width: 730px;
    overflow: hidden;

    &-img {
      position: absolute;
      right: -320px;
      top: 50px;
    }
  }

  .form-search {
    margin-bottom: 40px;
  }

  h1 {
    margin-bottom: 22px;
  }

  &__sub {
    font-size: 20px;
    margin-bottom: 12px;
  }

  @media screen and (min-width: 1921px) {
    &__container {
      margin-left: auto;
      margin-right: auto;
    }

    &__information {
      margin-left: 0;
    }

    &__area {
      overflow: unset;
      width: 1000px;

      &-img {
        right: 0;
      }

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 1660px) {
    &__information {
      margin-left: 30px;
    }

    &__sub {
      font-size: 20px;
    }
  }

  @media (max-width: 1480px) {
    height: 650px;

    &__area {
      width: 600px;

      &-img {
        img {
          width: 920px;
        }
      }
    }

    &__information {
      width: 55%;
    }

    .form-search {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1360px) {
    height: 600px;

    &__area {
      width: 570px;

      &-img {
        right: -300px;

        img {
          width: 870px;
        }
      }
    }

    h1 {
      margin-bottom: 15px;
    }

    &__sub {
      margin-bottom: 8px;
      font-size: 19px;
    }
  }

  @media (max-width: 1199px) {
    height: auto;
    padding-top: 48px;

    &__container {
      flex-wrap: wrap;
    }

    &__information {
      width: 100%;
      margin-left: 20px;
      margin-right: 20px;
    }

    &__area {
      width: 750px;
      margin-left: auto;
      padding-top: 20px;
      padding-bottom: 20px;

      &-img {
        position: relative;
        top: 0;
        right: -100px;

        img {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 20px;

    &__information {
      margin-left: 10px;
      margin-right: 10px;
    }

    &__area {
      width: 100%;
    }

    h1 {
      font-size: 20px;
      margin-bottom: 10px;
      line-height: 30px;
    }

    &__sub {
      margin-bottom: 7px;
      font-size: 16px;
    }
  }
}
