@font-face {
  font-family: "Inter-Medium";
  src: url("../fonts/Inter/Inter-Medium/Inter-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Inter-Regular";
  src: url("../fonts/Inter/Inter-Regular/Inter-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("../fonts/Inter/Inter-SemiBold/Inter-SemiBold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto/RobotoRegular/RobotoRegular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto/RobotoBold/Roboto-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff");
  font-display: swap;
}
