.home-page {
  justify-content: space-between;

  &__left {
    display: flex;
    flex-direction: column;
    padding-left: 70px;
    padding-top: 70px;

    @media screen and (max-width: 1360px) {
      padding-left: 40px;
    }

    @media (max-width: 1199px) {
      width: 100%;
      padding: 40px 30px 20px;
    }

    @media (max-width: 768px) {
      padding: 30px 15px 0;
    }
  }

  &__right {
    width: 1000px;
    background: #fff;

    @media screen and (max-width: 1600px) {
      width: 59%;
    }

    @media (max-width: 1199px) {
      width: 100%;
      margin: 0 30px 30px;
    }

    @media (max-width: 768px) {
      margin: 10px 15px 20px;
    }
  }

  .page-link {
    background: #fff;
    font-size: 17px;
    height: 100%;
    width: 100%;
    color: #363e68;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    justify-content: center;

    svg {
      margin-right: 24px;

      path {
        fill: var(--gray);
      }
    }

    &.active {
      background: var(--primary);
      color: #fff;

      svg path {
        fill: #fff;
      }
    }

    @media screen and (max-width: 1360px) {
      font-size: 16px;

      svg {
        margin-right: 18px;
      }
    }

    @media screen and (max-width: 992px) {
      font-size: 14px;
    }
  }

  &-link {
    position: absolute;
    top: 30%;
    left: 70px;

    li {
      margin-bottom: 16px;
      width: 300px;
      height: 70px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 1360px) {
      left: 40px;

      li {
        width: 230px;
        height: 62px;
      }
    }

    @media (max-width: 1199px) {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      padding-top: 10px;

      li {
        margin-right: 15px;
      }
    }

    @media screen and (max-width: 992px) {
      li {
        width: 200px;
        height: 53px;
      }
    }

    @media (max-width: 576px) {
      justify-content: space-between;

      li {
        width: 49%;
        margin-right: 0;
      }
    }
  }
}

.content-home {
  width: 100%;

  &__title {
    font-size: 22px;
    border-bottom: 1px solid #dadce3;
    color: #000;
    padding: 30px 50px;
  }

  &__form {
    padding: 30px 50px;
  }

  .form {
    .form__line {
      .form__item:first-child {
        width: 280px;
        margin-right: 25px;
      }

      .form__item:last-child {
        width: calc(100% - 310px);
      }
    }

    button {
      width: 280px;
      padding: 11px 15px;
      margin-top: 15px;
    }
  }

  @media screen and (max-width: 1600px) {
    &__title,
    &__form {
      padding: 24px 35px;
    }
  }

  @media screen and (max-width: 1360px) {
    &__title {
      padding: 19px 30px;
      font-size: 20px;
    }

    &__form {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @media screen and (max-width: 992px) {
    &__title {
      padding: 15px 20px;
      font-size: 17px;
    }

    &__form {
      padding-left: 20px;
      padding-right: 20px;

      legend {
        margin-bottom: 14px;
      }
    }
  }

  @media (max-width: 768px) {
    .form {
      .form__item {
        margin-bottom: 10px;
      }

      .form__line {
        justify-content: space-between;

        .form__item {
          width: 49%;
        }

        .form__item:first-child {
          width: 49%;
          margin-right: 0;
        }

        .form__item:last-child {
          width: 47.5%;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .form {
      .form__line {
        .form__item:first-child {
          width: 100%;
        }

        .form__item:last-child {
          width: 100%;
        }
      }

      .button {
        width: 100%;
      }
    }
  }

  p {
    font-size: 14px;

    a:focus,
    a:hover {
      text-decoration: underline;
    }
  }
}
