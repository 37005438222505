.page-setting {
  padding: 30px 30px 50px;

  .change-avatar {
    padding-right: 40px;
    width: 254px;
  }

  .form {
    &__group {
      width: calc(100% - 300px);
    }

    &__item {
      width: 450px;

      &--width {
        width: 650px;
      }

      &--textarea {
        width: 100%;
      }
    }

    .form__line {
      .form__item {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .button-save {
      width: 240px;
    }
  }

  @media (max-width: 1300px) {
    .form {
      .form-line {
        .item {
          &:last-child {
            margin-left: 0;
            width: 400px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 20px 20px 30px;
  }

  @media screen and (max-width: 992px) {
    .form {
      &__group {
        width: 100%;
      }

      &__item {
        margin-bottom: 15px;

        &--width {
          width: 100%;
        }
      }
    }

    .button-save {
      padding: 9px 20px;
      font-size: 15px;
    }

    .change-avatar {
      padding-right: 0;
      padding-bottom: 20px;
    }
  }

  @media (max-width: 576px) {
    padding: 15px 15px 20px;

    .form {
      justify-content: center;

      &__item,
      .button-save {
        width: 100%;
      }

      .form-line {
        .item {
          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }
}
