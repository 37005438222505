.average-ball {
  border: 1px solid #d2d8e4;
  border-radius: 0 4px 4px 0;
  font-size: 14px;
  height: 43px;

  &__head {
    padding: 0 16px;
    height: 100%;
  }

  span {
    color: #868e95;
  }

  .rc-slider {
    height: auto;
    top: -5px;
  }

  .rc-slider-track {
    background-color: #275adb;
  }

  .rc-slider-handle {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05), 0 6px 16px rgba(0, 0, 0, 0.05);
    border: solid 4px #275adb;
    background-color: #fff;

    &:hover,
    &:focus {
      border-color: #275adb;
    }
  }
}
