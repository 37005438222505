@import '../variables.scss';

.card-preview {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 20px;
  justify-content: space-between;
  margin-bottom: 15px;

  &__img {
    width: 70px;
    height: 70px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--border {
      border-radius: 50%;
    }

    &--custom {
      border-radius: 6px;
    }

    @media screen and (max-width: 768px) {
      width: 100px;
      height: 100px;
    }

    @media (max-width: 576px) {
      margin-bottom: 10px;
    }
  }

  &__title {
    color: #3b4459;
    padding-bottom: 10px;
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > div {
      margin-right: 15px;
    }

    span {
      color: #6e7784;
      font-size: 14px;
    }

    &--primary {
      color: var(--primary);
    }

    @media screen and (max-width: 1200px) {
      font-size: 16px;

      > div {
        margin-right: 13px;
      }

      span {
        font-size: 13px;
      }
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 5px;
      font-size: 15px;
    }
  }

  &__content {
    width: calc(100% - 95px);
    color: #50575c;

    ul.card-preview__list {
      li {
        font-size: 14px;
        color: #50575c;
        margin-right: 25px;

        svg {
          margin-right: 10px;
        }

        span {
          vertical-align: middle;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &--pt {
        padding-top: 10px;
      }

      @media screen and (max-width: 992px) {
        li {
          font-size: 13px;
          margin-right: 17px;

          svg {
            margin-right: 7px;
            width: 18px;
          }
        }
      }

      @media screen and (max-width: 768px) {
        &--pt {
          padding-top: 0;
        }

        li {
          margin-bottom: 7px;
          margin-right: 0;
          width: 100%;
          display: flex;

          span {
            width: calc(100% - 25px);
          }
        }
      }
    }

    &--width {
      width: 100%;
    }

    .card-preview__group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @media screen and (max-width: 1200px) {
      .card-preview__group {
        .date {
          font-size: 13px;
        }
      }
    }

    @media screen and (max-width: 992px) {
      .card-preview__group {
        .card-preview__title {
          width: 100%;
          order: 1;
        }

        .date {
          padding-bottom: 5px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      width: calc(100% - 75px);
    }

    @media (max-width: 576px) {
      width: 100%;

      .card-preview__group {
        .card-preview__title--primary {
          > div {
            order: 1;
            width: 100%;
          }

          span {
            padding-bottom: 4px;
          }
        }
      }
    }
  }

  &__info {
    font-size: 14px;
    font-family: "Roboto-Regular", sans-serif;
    padding-bottom: 10px;

    p {
      margin-top: 10px;
    }

    @media screen and (max-width: 1200px) {
      font-size: 13px;
    }

    @media screen and (max-width: 480px) {
      p {
        margin-top: 3px;
      }
    }
  }

  &__pay,
  .salary {
    color: #23282e;

    @media screen and (max-width: 480px) {
      font-size: 15px;
    }
  }

  &__foot {
    .salary {
      margin-right: 16px;
      margin-bottom: 7px;
    }

    .badge {
      margin-right: 8px;
      margin-bottom: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 992px) {
      .salary {
        margin-right: 9px;
      }
    }
  }

  .blocked {
    margin-left: 10px;
    color: var(--red);
    display: none;
  }

  &:hover {
    .badge--secondary {
      background: rgba(39, 174, 96, 0.1);
      color: #27ae60;
    }

    .blocked {
      display: block;
    }
  }

  &--custom {
    box-shadow: none;
    background: transparent;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    padding: 15px 10px;
  }
}
