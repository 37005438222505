.img-wrap {
  width: 130px;
  height: 130px;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1200px) {
    width: 105px;
    height: 105px;
  }

  @media screen and (max-width: 480px) {
    width: 49%;
    margin-right: 0;
  }
}

.photo-open {
  background: #fff;
  box-shadow: -8px 8px 16px rgba(67, 86, 100, 0.0780977), -4px 4px 8px rgba(67, 86, 100, 0.08);
  border-radius: 8px;
  width: 130px;
  height: 130px;
  padding: 8px;
  cursor: pointer;
  display: inline-flex;

  &__inner {
    border: 1px dashed #dadce3;
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  @media screen and (max-width: 1200px) {
    width: 105px;
    height: 105px;

    svg {
      width: 26px;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.wrapper-images {
  background: #f4f6fa;
  border: 1px solid #d2d8e4;
  border-radius: 4px;
  padding: 20px 20px 12px;

  button {
    width: 32px;
    height: 35px;
    background: #ff5145;
    border-radius: 2px;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .dropzone {
    display: inline-flex;
    margin-bottom: 8px;
    margin-right: 8px;
  }

  @media screen and (max-width: 1200px) {
    button {
      width: 28px;
      height: 28px;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 10px 10px;
  }

  @media screen and (max-width: 480px) {
    justify-content: space-between;

    .dropzone {
      width: 49%;
      margin-right: 0;
    }
  }
}

.upload-file {
  background: var(--primary-gradient);
  color: #fff;
  display: inline-flex;
  align-items: center;
  padding: 14px 20px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;

  svg {
    margin-left: 10px;
  }
}

.document {
  border: 1px solid #d2d8e4;
  border-radius: 4px;
  padding: 10px;
  color: var(--primary);
  font-size: 14px;

  a {
    align-items: center;

    svg {
      margin-right: 12px;
    }
  }

  .delete {
    background: #99aac3;
    border-radius: 3px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
  }
}

.documents-content {
  .dropzone {
    margin-bottom: 7px;
    display: inline-flex;
  }

  .document {
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-flex;
  }

  @media (max-width: 576px) {
    .dropzone {
      width: 100%;

      .upload-file {
        width: 100%;
        justify-content: center;
      }
    }

    .document {
      margin-right: 0;
      width: 100%;
    }
  }
}

.document-preview {
  background: rgba(244, 246, 250, 0.5);
  border: 1px solid #dadce3;
  border-radius: 4px;
  padding: 13px 15px;
  display: flex;
  align-items: center;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }

  .icon {
    margin-right: 16px;
    width: auto;
  }

  @media screen and (max-width: 992px) {
    .icon {
      margin-right: 10px;
    }
  }
}

.documents-block {
  .document-preview {
    margin-right: 16px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 992px) {
    .document-preview {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  @media screen and (max-width: 768px) {
    .document-preview {
      flex: 1;
    }
  }
}
