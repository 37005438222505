.modal-content {
  border-radius: 4px;
  border-color: #d2d8e4;
  box-shadow: 0 4px 20px rgba(79, 86, 105, 0.16);
  background-color: #fff;
  width: 100%;

  .modal-header {
    padding: 0;
    border-radius: 0;
    border-bottom: none;

    button {
      position: absolute;
      top: 14px;
      right: 14px;
    }
  }
}

.modal-base {
  h5 {
    font-size: 18px;
    margin-bottom: 15px;

    @media (max-width: 576px) {
      font-size: 16px;
    }

    @media screen and (max-width: 480px) {
      text-align: center;
    }
  }

  .modal-content {
    padding: 20px 30px 30px 30px;
    max-width: 500px;
    position: relative;

    @media screen and (max-width: 992px) {
      padding: 15px 20px;
    }
  }

  .modal-body {
    .button {
      padding: 9px 45px;

      @media screen and (max-width: 992px) {
        font-size: 13px;
        padding: 8px 20px;
      }

      @media screen and (max-width: 480px) {
        text-align: center;
        width: 100%;
      }
    }

    .form {
      .form__item {
        margin-bottom: 10px;
      }

      .form__line {
        .form__item {
          flex: 1;

          &:first-child {
            margin-right: 9px;
          }
        }
      }

      @media screen and (max-width: 576px) {
        .form__item {
          width: 100%;
        }

        .form__line {
          .form__item {
            flex: none;
            width: 100%;

            &:first-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-right: 9px;
    margin-left: 9px;
  }
}

.modal-upload {
  .modal-content {
    padding: 20px 30px 30px 30px;
    max-width: 500px;

    @media screen and (max-width: 992px) {
      padding: 15px 20px;
    }
  }

  .modal-body {
    .form {
      label {
        font-size: 14px;
      }

      .item {
        margin-bottom: 15px;
      }

      .button-submit {
        width: 200px;
        font-size: 15px;
      }

      @media screen and (max-width: 992px) {
        .button-submit {
          font-size: 14px;
        }

        .upload {
          padding-bottom: 10px;

          label {
            padding: 10px 15px;
          }
        }
      }

      @media screen and (max-width: 480px) {
        .button-submit {
          width: 100%;
          padding: 8px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-right: 9px;
    margin-left: 9px;
  }
}

.modal-support {
  .modal-content {
    max-width: 630px;

    .modal-header {
      text-align: center;
      border-bottom: 1px solid #d2d8e4;
      padding: 25px 40px;

      h5 {
        font-size: 20px;
        margin-bottom: 16px;
      }

      p {
        font-size: 15px;
      }

      @media screen and (max-width: 1200px) {
        padding-top: 20px;
        padding-bottom: 20px;

        h5 {
          font-size: 19px;
          margin-bottom: 10px;
        }
      }

      @media screen and (max-width: 992px) {
        h5 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }
      }

      @media screen and (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;

        h5 {
          font-size: 16px;
          line-height: 1.3;
        }

        p {
          font-size: 13px;
        }
      }
    }

    .modal-body {
      padding: 24px 40px;

      .form {
        .item-group {
          padding-bottom: 10px;
        }

        .button {
          width: 100%;
        }
      }

      @media screen and (max-width: 1200px) {
        padding: 20px 25px;

        .form {
          .button {
            padding: 9px;
          }
        }
      }

      @media screen and (max-width: 768px) {
        padding: 18px 14px;

        .form {
          textarea {
            height: 100px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.modal-feedback {
  h5 {
    font-size: 19px;
    margin-bottom: 12px;
  }

  .modal-content {
    max-width: 700px;
    padding: 30px 35px;

    .modal-body {
      .form {
        textarea {
          height: 180px;
        }

        &__item {
          margin-bottom: 18px;
        }

        &__item--bg {
          background: #f4f6ff;
          padding: 18px 20px;
          border-radius: 4px;

          label {
            margin-bottom: 15px;
          }

          @media screen and (max-width: 1200px) {
            padding: 16px 15px;
          }

          @media (max-width: 576px) {
            padding: 14px 12px;
          }
        }
      }

      .button-submit {
        width: 280px;
        padding: 14px;

        @media (max-width: 1366px) {
          padding: 12px;
          width: 250px;
        }

        @media (max-width: 576px) {
          margin-right: auto;
          margin-left: auto;
          display: block;
        }

        @media screen and (max-width: 480px) {
          width: 100%;
          font-size: 13px;
          padding: 10px;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      padding: 25px;
    }

    @media (max-width: 576px) {
      padding: 15px;
    }
  }

  @media (max-width: 800px) {
    margin-right: 10px;
    margin-left: 10px;

    h5 {
      font-size: 17px;
      margin-bottom: 7px;
    }
  }

  @media (max-width: 576px) {
    h5 {
      font-size: 16px;
      text-align: center;
    }
  }
}
