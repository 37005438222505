/* внутри страницы универов для заголовка */
.size {
  font-size: 28px;
  margin-bottom: 21px;

  @media screen and (max-width: 1600px) {
    font-size: 26px;
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 15px;
    font-size: 25px;
  }

  @media screen and (max-width: 992px) {
    font-size: 22px;
  }

  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
}

.indent {
  padding: 10.5px 20px;
  margin-left: 10px;
}

.heading {
  padding-top: 40px;
}
