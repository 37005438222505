@import "../variables";

.help-card {
  box-shadow: 0 12px 64px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  transition: all 0.3s ease;
  background-color: #fff;

  &__title {
    font-size: 24px;
    margin-top: 20px;
  }

  &:hover {
    background: var(--primary-gradient);

    .help-card__title {
      color: #fff;
    }

    svg path {
      fill: #fff;
    }
  }

  @media (max-width: 1366px) {
    //width: 32%;
    height: 215px;

    &__title {
      font-size: 20px;
      margin-top: 15px;
    }
  }

  @media screen and (max-width: 992px) {
    &__title {
      font-size: 17px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;
    height: 160px;
    flex-basis: 100%;

    svg {
      width: 60px;
      height: 60px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.help-card-wrap {
  justify-content: center;
  padding: 60px 10% 100px 10%;

  .help-card + .help-card {
    margin-left: 24px;
  }

  @media (max-width: 1366px) {
    justify-content: space-between;
    padding: 30px 0 60px 0;

    .help-card + .help-card {
      margin-left: 17px;
    }
  }

  @media screen and (max-width: 1200px) {
    padding-top: 15px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 30px;

    .help-card + .help-card {
      margin-left: 0;
    }
  }
}

.technical-support {
  max-width: 650px;

  &__info {
    margin-left: 25px;
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
  }

  &__text {
    margin-top: 15px;
  }

  .button {
    margin-top: 24px;
    padding: 8px 35px;
  }

  @media screen and (max-width: 1200px) {
    max-width: 48%;

    svg {
      width: 100px;
      height: 100px;
    }

    h3 {
      font-size: 16px;
    }

    .button {
      margin-top: 17px;
    }
  }

  @media screen and (max-width: 992px) {
    &__info {
      margin-left: 15px;
      width: calc(100% - 75px);
    }

    &__text {
      margin-top: 8px;
    }

    svg {
      width: 60px;
      height: 60px;
    }

    h3 {
      font-size: 15px;
    }

    .button {
      padding: 6px 30px;
      margin-top: 15px;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 992px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.technical-support-wrap {
  background: #f4f6fa;
  border-radius: 8px;
  padding: 50px;

  .technical-support + .technical-support {
    margin-left: 50px;
  }

  @media screen and (max-width: 1200px) {
    padding: 30px;

    .technical-support + .technical-support {
      margin-left: 30px;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 25px 20px;

    .technical-support + .technical-support {
      margin-left: 0;
    }
  }
}

/* внутренняя страница */
.help-page {
  font-size: 14px;
  padding: 15px 25px 20px;
  min-height: 500px;

  &__title {
    font-size: 25px;
    color: #444;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 15px;
  }

  p {
    margin-bottom: 10px;
  }

  a {
    color: var(--primary-gradient);
    text-decoration: underline;
  }

  @media screen and (max-width: 1600px) {
    &__title {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 1200px) {
    img {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 992px) {
    &__title {
      font-size: 18px;
    }
  }

  @media (max-width: 576px) {
    &__title {
      font-size: 15px;
      line-height: 1.3;
    }
  }
}

.accordion-block {
  @media (max-width: 992px) {
    max-height: 350px;
    overflow-y: auto;

    .MuiExpansionPanelDetails-root {
      > div {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    height: 315px;
  }
}

.help-link {
  width: 100%;

  li {
    a {
      color: #50575c;
      width: 100%;
      height: 100%;
      display: block;
      padding: 5px 20px;

      &.active {
        color: #275adb;
        text-decoration: underline;
        background: rgba(39, 90, 219, 0.1);
        border-left: 2px solid var(--primary);
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .inner-card--help {
    .inner-card__content {
      padding-top: 20px;
    }
  }
}
