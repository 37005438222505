.card-personal {
  .form {
    .form__item {
      margin-right: 20px;
      width: 260px;

      .button {
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: 1360px) {
        width: calc((100% / 4) - 20px);
      }

      @media screen and (max-width: 992px) {
        width: calc((100% / 2) - 20px);
        margin-bottom: 15px;

        .button {
          font-size: 15px;
        }
      }

      @media (max-width: 576px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;

        .button {
          padding: 8px;
        }
      }
    }
  }

  .hasError {
    line-height: 15px;
  }
}
