.block-inner {
  box-shadow: 0 0.375rem 1.125rem rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  background: #fff;
  padding: 1rem 1.5rem;
  margin-bottom: 1.5625rem;

  .button {
    padding: 10px 25px;
  }

  .button-mr {
    margin-right: 20px;
  }

  @media screen and (max-width: 1600px) {
    padding: 14px 20px;

    .button {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  @media screen and (max-width: 992px) {
    margin-bottom: 20px;

    .button {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 11px 15px;

    .button-mr {
      margin-right: 10px;
    }
  }

  @media (max-width: 719px) {
    justify-content: space-between;

    .button {
      width: 100%;
      margin-bottom: 8px;
      padding-left: 17px;
      padding-right: 17px;
    }

    .button-mr {
      margin-right: 0;
    }

    .button-width {
      width: 100%;
    }
  }
}

///* загрузить фотографии в настройках */

.avatar-load {
  label {
    color: #fff;
    background: var(--green-gradient);
    cursor: pointer;
    padding: 12px 15px;
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    span {
      padding-left: 9px;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }

  @media screen and (max-width: 992px) {
    label {
      font-size: 13px;
    }
  }
}

.change-avatar {
  .button-action {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 26px;
    height: 26px;
    opacity: 0;
    transition: opacity 0.5s;

    svg {
      width: 15px;
    }
  }

  &__img {
    background: #f3f3f8;
    border: 2px solid #d2d8e4;
    border-radius: 4px;
    height: 150px;
    justify-content: center;

    &:hover {
      .button-action {
        opacity: 1;
      }
    }
  }

  &__viewing {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 10px;
    text-align: center;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  &__load {
    width: 100%;
    margin-top: 16px;
  }

  @media screen and (max-width: 992px) {
    label {
      padding: 10px 15px;
    }
  }
}
