.badge {
  font-size: 14px;
  border-radius: 4px;
  display: inline-flex;
  padding: 4px 8px;
  color: #586168;
  transition: all 0.3s ease;

  &--info {
    background: #f2f4f7;

    span {
      color: var(--primary);
      padding-left: 3px;
    }
  }

  &--secondary {
    border: 1px solid #d2d8e4;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  &--primary {
    background: linear-gradient(0deg, #2c71f6, #2c71f6), linear-gradient(0deg, #4865ea, #4865ea), #4878fe;
    border-radius: 4px;
    color: #fff;
  }

  &--light {
    background: #eaf1fe;
    color: #2865db;
  }

  @media screen and (max-width: 1200px) {
    font-size: 13px;
    padding: 2px 5px;
  }
}

.sky-toolbar-wrap {
  position: relative;

  .sky-toolbar {
    position: fixed;
    right: 5px;
    top: 150px;
    z-index: 9999;

    &__icon {
      width: 42px;
      background: var(--red);
      padding-top: 6px;
      padding-bottom: 6px;
      text-align: center;
      margin-left: 17px;

      svg {
        width: 30px;

        path {
          fill: #fff;
        }
      }
    }

    &__content {
      max-width: 400px;
      font-size: 13px;
      color: #fff;
      text-align: center;
      line-height: 1.3;
      background-color: rgb(238, 54, 54);
      padding: 10px;
      border-radius: 3px;
    }
  }
}
