.feedback-vacancy {
  width: 100%;
  margin-top: 30px;

  h3 {
    font-size: 23px;
    margin-bottom: 16px;
    color: #2d3033;
  }
}

