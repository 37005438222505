.pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  &__link {
    width: 40px;
    height: 40px;
    border: 1px solid #d2d8e4;
    border-radius: 4px;
    font-size: 14px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    &--option {
      .pagination__link {
        width: 140px;
        color: #50575c;

        span {
          margin-right: 12px;
        }
      }
    }

    &--active {
      .pagination__link {
        background: var(--primary-gradient);
        color: #fff;
        border-color: var(--primary-gradient);
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 25px;

    &__item {
      margin-right: 10px;

      &--option {
        .pagination__link {
          width: 125px;
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    flex-wrap: wrap;

    &__item {
      &--option {
        width: 100%;
        margin-right: 0;

        .pagination__link {
          width: 100%;
        }
      }

      &--prev {
        margin-bottom: 10px;
      }

      &--next {
        margin-top: 10px;
      }
    }
  }
}
