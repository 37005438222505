.login-page-wrap {
  justify-content: center;
  padding-top: 5%;
  padding-bottom: 5%;
}

.login-page {
  width: 500px;
  background: #ffff;
  box-shadow: 0 8px 48px rgba(52, 64, 95, 0.06);
  border-radius: 8px;

  &__title {
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;

    @media screen and (max-width: 1200px) {
      margin-bottom: 15px;
    }

    @media screen and (max-width: 768px) {
      font-size: 22px;
    }

    @media (max-width: 576px) {
      font-size: 18px;
    }
  }

  .form {
    &__item {
      @media (max-width: 576px) {
        margin-bottom: 12px;
      }
    }
  }

  .button {
    width: 100%;
    padding: 12px;

    @media screen and (max-width: 576px) {
      font-size: 13px;
      padding: 8px;
    }
  }

  &__content {
    padding: 35px 50px;

    .form__line {
      .button {
        width: 170px;
      }
    }

    .form__group {
      a {
        display: block;
        font-size: 15px;
        margin-bottom: 2px;
      }
    }

    @media screen and (max-width: 1200px) {
      padding: 30px 45px;

      label {
        font-size: 14px;
      }

      input {
        padding: 8px 10px;
      }

      .css-tpfbus-control {
        padding-top: 0;
        padding-bottom: 0;
      }

      .form__line {
        a {
          font-size: 15px;
        }

        .button {
          padding: 9px 20px;
        }
      }
    }

    @media (max-width: 576px) {
      padding: 20px;

      .button {
        font-size: 15px;
      }
    }

    @media screen and (max-width: 480px) {
      .form__line {
        .button {
          width: 100%;
          padding: 7px;
        }

        a {
          font-size: 14px;
        }
      }

      .form__group {
        width: 100%;
        text-align: center;
        padding-top: 15px;
      }
    }
  }

  &__footer {
    text-align: center;
    padding: 0 60px 40px;

    @media screen and (max-width: 1200px) {
      padding: 0 45px 30px;
    }

    @media screen and (max-width: 480px) {
      padding: 0 20px 30px;
    }
  }

  p {
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
    color: #68727a;

    span {
      color: var(--dark);
    }
  }

  &__inner {
    &-head {
      border-bottom: 1px solid #d2d8e4;
      padding: 25px;
    }

    p {
      margin-bottom: 20px;
    }

    &-content {
      padding: 30px 35px;
    }

    .login-page__title {
      margin-bottom: 0;
    }

    @media screen and (max-width: 1200px) {
      &-head {
        padding: 20px 17px;
      }

      &-content {
        padding: 25px;
      }
    }

    @media (max-width: 576px) {
      &-head {
        padding: 15px;
      }

      &-content {
        padding: 17px;
      }
    }
  }

  @media (max-width: 576px) {
    width: 100%;
    margin-right: 15px;
    margin-left: 15px;
  }
}
