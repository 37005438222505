@import '../variables';

.list-main {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &__item {
    display: inline-block;
  }

  &__link {
    padding: 12px 20px;
    display: block;

    svg path {
      fill: var(--primary);
    }

    span {
      padding-left: 10px;
    }

    &.active {
      background: var(--primary-gradient);

      svg path {
        fill: #fff;
      }

      span {
        color: #fff;
      }
    }

    @media screen and (max-width: 1200px) {
      font-size: 14px;
      padding: 10px 15px;
    }

    @media screen and (max-width: 480px) {
      padding: 8px 10px;

      svg {
        width: 15px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    overflow: scroll;
    flex-wrap: nowrap;

    &__item {
      width: auto;
      flex-shrink: 0;
    }
  }
}

.list-main::-webkit-scrollbar {
  display: none;
}
