@import "bootstrap-reboot.css";
@import 'variables.scss';
@import 'mixins';

#root,
html,
body {
  height: 100%;
}

body {
  margin: 0;
  min-width: 320px;
  color: $color-text;
  background-color: #f1f4f7;
  font-family: "Inter-Regular", sans-serif;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}

main {
  flex-grow: 1;
  position: relative;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

a {
  text-decoration: none;
  color: var(--primary);

  &:hover {
    text-decoration: none;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

@media (max-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1201px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (min-width: 1800px) {
  .container {
    max-width: 1770px;
    padding-right: 0;
    padding-left: 0;
  }
}

.container-fluid {
  max-width: 1900px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.flex-element {
  display: flex;
  flex-wrap: wrap;
}

.flex-element--align-center {
  align-items: center;
}

.flex-element--justify-sb {
  justify-content: space-between;
}

.flex-element--options {
  justify-content: space-between;
  align-items: center;
}

.responsive-img {
  width: 100%;
}

.section-white {
  background: #fff;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #68727a;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #68727a;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #68727a;
}

:-moz-placeholder { /* Firefox 18- */
  color: #68727a;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.inner-page {
  padding-top: 40px;

  &--pb {
    padding-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 20px;

    &--pb {
      padding-bottom: 20px;
    }
  }
}

@import './base';

.bg-card {
  background-color: #fff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;

  &--mt {
    margin-top: 35px;

    @media screen and (max-width: 1200px) {
      margin-top: 25px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }

  &--inner {
    padding: 20px 25px;

    @media screen and (max-width: 1200px) {
      padding: 15px;
    }

    @media screen and (max-width: 480px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.information-block {
  padding: 20px;
  margin-bottom: 20px;

  p {
    font-size: 15px;
  }
}

.item {
  position: relative;

  .item__group {
    position: relative;
  }
}

.hasError {
  font-size: 13px;
  color: #cb0a0a;

  &--pb {
    padding-bottom: 10px;
    display: block;
  }
}

.help-block {
  font-size: 13px;
  margin-top: 2px;
  color: #cd0100;
}

@import "button";
@import "app";
