@import "../mixins";

.statistic {
  &__value {
    color: #3b4459;
    font-size: 24px;
  }

  &__text {
    color: rgba(70, 76, 81, 0.8);
    font-size: 15px;
  }

  &__content {
    margin-left: 17px;
    width: calc(100% - 42px);
  }

  @media (max-width: 1366px) {
    &__value {
      font-size: 22px;
    }

    &__text {
      font-size: 14px;
    }
  }
}

.statistic-wrap {
  .statistic {
    flex: 1.3 1;
    margin-right: 40px;

    &-min {
      flex-grow: 1;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 1660px) {
      margin-right: 25px;
    }

    @media (max-width: 1200px) {
      margin-right: 10px;
    }

    @media (max-width: 767px) {
      flex: none;
      width: 100%;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
