.block-table {
  display: block;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  width: 100%;

  thead th,
  tbody td {
    border-color: #ebeef2;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;

    @media screen and (max-width: 1600px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  thead {
    background: #fff;

    th {
      vertical-align: bottom;
      border-bottom: 1px solid #dee2e6;
      color: var(--gray);
      font-family: "Inter-SemiBold", sans-serif;
      font-weight: normal;
      font-size: 14px;
      text-align: left;

      &:first-child {
        padding-left: 25px;
      }
    }

    .line {
      display: flex;
      align-items: center;

      span {
        margin-right: 15px;
      }
    }

    @media screen and (max-width: 1200px) {
      th {
        font-size: 13px;
      }
    }

    @media screen and (max-width: 992px) {
      th {
        font-size: 12px;
        vertical-align: middle;

        &:first-child {
          padding-left: 15px;
        }
      }
    }
  }

  tbody {
    tr {
      background: #fff;

      &:hover {
        background: #f4f6fa;
      }
    }

    td,
    a {
      color: #3b4459;
      vertical-align: middle;
    }

    td {
      a {
        color: var(--primary);
        display: block;
      }

      &:first-child {
        padding-left: 25px;
      }
    }

    a:focus {
      outline: none;
    }

    @media screen and (max-width: 1200px) {
      td {
        font-size: 14px;
      }
    }

    @media screen and (max-width: 992px) {
      td {
        font-size: 13px;

        &:first-child {
          padding-left: 15px;
        }
      }
    }
  }

  &--shadow-none {
    box-shadow: none;
  }

  .ml {
    margin-left: 12px;
  }

  .mr {
    margin-right: 12px;
  }

  .group-text {
    max-width: 74px;
    font-size: 14px;

    .display-block {
      padding-top: 10px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    @media screen and (max-width: 992px) {
      display: inline-flex;
      max-width: 100%;
      align-items: center;

      .display-block {
        padding-top: 0;
        margin-left: 10px;
      }
    }
  }

  .btn-group {
    display: flex;

    .button-action {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 768px) {
      .button-action {
        margin-right: 7px;
      }
    }
  }
}

.table.table--head-transparent {
  thead {
    background: transparent;

    th {
      border: none;
    }
  }
}

.table.table--upper {
  thead {
    th {
      text-transform: uppercase;
    }
  }
}

.table.table--spacing {
  border-spacing: 0 10px;
  border-collapse: separate;

  thead {
    th {
      padding: 0 0 10px 0;
    }
  }

  tbody {
    tr {
      background: transparent;
    }

    td {
      border-top: none;
      background: #fff;

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    thead {
      th {
        padding: 0 0 5px 0;
      }
    }
  }
}

.table.table--small-size {
  th,
  td {
    font-size: 12px;
  }
}

.responsive-custom {
  .card-preview {
    margin-bottom: 0;
  }

  @media screen and (max-width: 992px) {
    .card-preview {
      width: 370px;

      .card-preview__content {
        width: calc(100% - 110px);
      }
    }
  }
}
