$color-text: #50575c;
$dark: #3f4448;

:root {
  --primary: #275adb;
  --primary-gradient: linear-gradient(0deg, #2c71f6, #2c71f6), linear-gradient(0deg, #4865ea, #4865ea), #4878fe;
  --green: #178727;
  --green-gradient: linear-gradient(0deg, #2bae50, #2bae50), #12b62c;
  --red: #ee3636;
  --gray: #6b7686;
  --dark: #3f4448;

  /* media */
  //--desktop-lg: 1600px;
  //--desktop-md: 1360px;
  //--desktop: 1200px;
  //--laptop: 992px;
  //--tablet: 768px;
  //--phone-md: 576px;
  //--phone: 480px;
}
