.message-block {
  padding: 20px;

  &__content {
    background: #f3f3f8;
    border-radius: 4px;
    min-height: 100px;
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 14px;
    margin-top: 16px;
  }

  &--mb {
    margin-bottom: 24px;
  }

  @media (max-width: 1200px) {
    &__content {
      padding: 15px;
    }
  }

  @media (max-width: 768px) {
    padding: 15px;

    .min {
      font-size: 13px;
    }

    &--mb {
      margin-bottom: 16px;
    }
  }
}

.blog-head {
  max-width: 800px;

  @media (max-width: 992px) {
    max-width: 100%;
  }
}
