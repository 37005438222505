@import "../variables";

.card-bg {
  background: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.card-about {
  padding: 20px 30px 12px;

  &__head {
    padding-bottom: 15px;
    border-bottom: 1px solid #e9fcf2;
    color: #3b4459;

    &.flex-element {
      align-items: center;

      svg {
        margin-right: 17px;
      }
    }
  }

  &__body {
    padding-top: 22px;
    font-size: 14px;

    p {
      margin-bottom: 10px;
    }

    img {
      width: 100%;
    }

    .ql-syntax {
      white-space: inherit;
    }
  }

  &--height {
    min-height: 285px;
  }

  .title-post,
  .salary {
    color: #3b4459;
  }

  .salary {
    font-size: 16px;
  }

  //test
  .button-group {
    width: 100%;

    .button {
      padding: 10px 16px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    &--pt {
      padding-top: 16px;
    }
  }

  &__top {
    padding-bottom: 18px;
    border-bottom: 1px solid #e9fcf2;

    h3 {
      margin-bottom: 8px;
    }

    h4 {
      font-size: 16px;
      color: $dark;
    }
  }

  &__bottom {
    padding-top: 18px;
    font-size: 14px;

    .card-about-bottom-description {
      width: 100%;
      padding-top: 14px;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .badge {
    margin-bottom: 5px;
    margin-right: 5px;
  }

  @media screen and (max-width: 1600px) {
    padding: 17px 20px 12px;

    &__head {
      h3 {
        font-size: 17.5px;
      }
    }

    &--height {
      min-height: auto;
    }
  }

  @media screen and (max-width: 1200px) {
    &__head {
      h3 {
        font-size: 15px;
        margin-bottom: 5px;
      }

      &.flex-element {
        svg {
          margin-right: 8px;
          width: 18px;
          margin-bottom: 5px;
        }
      }

      &--amendments {
        padding-bottom: 10px;
      }
    }

    &__body {
      padding-top: 17px;
    }
  }

  @media screen and (max-width: 768px) {
    &__head {
      .card-about__top {
        padding-bottom: 10px;
      }

      h4 {
        font-size: 15px;
      }
    }

    .card-about__bottom {
      padding-top: 15px;
      font-size: 13px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 14px;
    padding-right: 14px;
  }

  @media (max-width: 576px) {
    .card-about__top {
      .card-about-group {
        width: 100%;
        order: 1;
        padding-top: 10px;
      }
    }
  }
}

.inner-card {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  &--custom {
    background: #fff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    display: inline-flex;
  }

  &--primary {
    justify-content: space-between;
  }

  .card-aside,
  aside {
    width: 325px;
  }

  &__content {
    width: calc(100% - 345px);
  }

  .mb {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 1200px) {
    .card-aside,
    aside {
      width: 295px;
    }

    &__content {
      justify-content: space-between;
      width: calc(100% - 310px);
    }
  }

  @media screen and (max-width: 992px) {
    .card-aside,
    aside {
      width: 100%;
    }

    &__content {
      width: 100%;
    }

    &--custom {
      .card-aside {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      }
    }
  }
}

.inner-card-body,
.card-photo {
  padding: 15px 20px;

  h3 {
    font-size: 18px;
    color: #3b4459;
  }

  @media (max-width: 1200px) {
    h3 {
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    padding: 17px 14px;
  }
}

.inner-card-body {
  h3 {
    margin-bottom: 16px;
    font-size: 18px;
    color: #3b4459;
  }

  &--indentation {
    padding: 20px;
  }

  &--border {
    border-left: 1px solid rgba(0, 0, 0, 0.09);
    padding-left: 25px;
  }

  &__item {
    &--border {
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }

  &--params {
    @media screen and (max-width: 768px) {
      h3 {
        display: none;
      }
    }
  }
}

.card-content {
  &__left {
    width: calc(100% - 630px);

    .card-about {
      width: 100%;
    }
  }

  &__right {
    width: 610px;
  }

  .date {
    font-size: 14px;
  }

  &__list {
    width: 100%;
    padding-top: 24px;
  }

  @media screen and (max-width: 1600px) {
    &__right {
      width: 540px;
    }

    &__left {
      width: calc(100% - 560px);
    }
  }

  @media (max-width: 1500px) {
    &__left,
    &__right {
      width: 100%;
    }

    &__left {
      margin-bottom: 12px;

      &--no-indentation {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .date {
      font-size: 12px;
    }
  }
}

.card-map {
  padding: 16px 20px;
  font-size: 14px;
  position: relative;

  &--phone {
    display: none;
  }

  h3 {
    font-size: 16px;
    color: #2d3033;
    margin-bottom: 8px;
  }

  img {
    width: 100%;
  }

  p {
    margin-bottom: 7px;
  }

  @media screen and (max-width: 1200px) {
    padding: 15px;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.card-info {
  h1 {
    color: #23272e;
    font-size: 18px;

    @media screen and (max-width: 1200px) {
      font-size: 17px;
    }

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__head {
    padding: 32px 20px 20px;

    &--center {
      text-align: center;
    }

    @media screen and (max-width: 992px) {
      &--center {
        text-align: left;
      }
    }

    @media screen and (max-width: 480px) {
      &--center {
        text-align: left;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 32px 20px 20px;
    }

    @media screen and (max-width: 480px) {
      text-align: center;
    }
  }

  &__img {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06), 0 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    width: 150px;
    max-height: 200px;
    object-fit: cover;
    display: block;
    overflow: hidden;

    &--circle {
      height: 150px;
      border-radius: 50%;
    }

    @media screen and (max-width: 992px) {
      margin-left: unset;
    }

    @media screen and (max-width: 480px) {
      margin-left: auto;
    }
  }

  &__params {
    padding-top: 12px;
  }

  &__body,
  &__foot {
    border-top: 2px solid #f3f3f8;

    h3 {
      color: #2d3033;
      font-size: 12px;
      margin-bottom: 15px;

      @media screen and (max-width: 480px) {
        margin-bottom: 10px;
      }
    }
  }

  &__body {
    padding: 20px;

    p {
      font-size: 14px;
    }

    ul li {
      font-size: 14px;
      margin-bottom: 13px;

      span {
        color: #68727a;
      }

      span:last-child {
        color: #2d3033;
        margin-left: 5px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 1200px) {
      padding: 15px;
    }
  }

  &__foot {
    padding: 16px 20px;

    .button-group {
      justify-content: space-between;

      .button {
        padding: 5px;
        width: 100%;
        font-size: 14px;

        + .button {
          margin-top: 5px;
        }
      }
    }

    .badge {
      margin-bottom: 4px;
      margin-right: 4px;
    }

    @media screen and (max-width: 1200px) {
      padding: 15px;
    }

    @media (max-width: 991px) and (min-width: 660px) {
      .button-group {
        justify-content: flex-start;

        .button {
          width: 30%;

          + .button {
            margin-left: 10px;
            margin-top: 0;
          }
        }
      }
    }

    @media screen and (max-width: 480px) {
      .button-group {
        .button {
          width: 100%;

          + .button {
            width: 100%;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

ul.characteristics-list {
  justify-content: space-between;

  .characteristics-list__list {
    text-align: center;
    padding-right: 18px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    span {
      display: block;
    }

    svg {
      margin-bottom: 8px;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 1360px) {
    .characteristics-list__list {
      padding-right: 10px;
    }
  }

  @media screen and (max-width: 992px) {
    justify-content: flex-start;

    .characteristics-list__list {
      padding-right: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    justify-content: space-around;

    .characteristics-list__list {
      font-size: 13px;
    }
  }
}

.card-university {
  justify-content: space-between;

  h3 {
    display: none;
  }

  &__icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__information {
    width: calc(100% - 125px);
    font-size: 14px;
  }

  &__title {
    margin-bottom: 14px;
    color: #3f4448;
  }

  p {
    margin-bottom: 6px;
  }

  .card-university__foot {
    &-content {
      margin-right: 8px;

      span {
        color: $dark;
        font-family: "Inter-SemiBold", sans-serif;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 992px) {
    &__icon {
      width: 80px;
      height: 80px;
    }

    &__information {
      width: calc(100% - 100px);
    }
  }

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    align-items: center;

    h3 {
      display: block;
      font-size: 16px;
      margin-right: 24px;
      margin-bottom: 0;
    }

    &__information {
      font-size: 13px;
      width: 100%;
      padding-top: 10px;
    }

    &__icon {
      width: 40px;
      height: 40px;
    }
  }
}
