.assessments-list {
  &__item {
    font-size: 14px;
    color: #68727a;
    border-bottom: 1px solid #d2d8e4;
    padding: 7px 0;
  }

  &__name {
    width: calc(100% - 45px);
  }

  &__mark {
    color: var(--primary);
  }

  @media screen and (max-width: 767px) {
    &__item {
      font-size: 13px;
    }
  }
}

.assessments-content {
  padding-right: 15px;
}
