@import '../variables';

.header {
  background: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);

  .logo {
    display: block;

    img {
      width: 98px;

      @media screen and (max-width: 480px) {
        width: 80px;
      }
    }
  }

  &-top {
    padding: 19px 0;
    border-bottom: 1px solid #f3f3f8;

    @media screen and (max-width: 768px) {
      padding: 12px 0;
    }
  }

  .header-block {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1600px) {
      .button {
        font-size: 13px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    @media screen and (max-width: 768px) {
      .button {
        height: 40px;
      }
    }

    @media (max-width: 576px) {
      .button-registration {
        display: none;
      }
    }

    @media screen and (max-width: 480px) {
      .button {
        padding: 0 10px;
        height: auto;
        border: none;

        span {
          display: none;
        }
      }
    }
  }

  &-bottom {
    .header-block {
      .notification {
        margin-right: 28px;
      }
    }

    @media screen and (max-width: 992px) {
      .header-block {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;
        border-bottom: 1px solid #e4e8ec;
      }

      .list-main {
        order: 1;
      }
    }
  }

  .hamburger {
    display: none;
    font: inherit;
    overflow: visible;
    margin: 0 20px 0 0;
    padding: 1px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;
    height: 23px;
    z-index: 150;

    &__box {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 20px;
    }

    &__inner {
      top: 0;
      display: block;
      transition: background-color 0s linear 0.13s;

      &::after,
      &::before {
        display: block;
        content: "";
      }

      &::before {
        top: 8px;
        transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      &::after {
        top: 16px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &__inner,
    &__inner::after,
    &__inner::before {
      position: absolute;
      width: 24px;
      height: 2px;
      background-color: #363e68;
    }

    &--active {
      .hamburger__inner {
        transition-delay: 0.22s;
        background-color: transparent;

        &::before {
          top: 0;
          transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
          transform: translate3d(0, 10px, 0) rotate(45deg);
        }

        &::after {
          top: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
          transform: translate3d(0, 10px, 0) rotate(-45deg);
        }
      }
    }

    &:focus {
      outline: none;
      border: 1px dashed rgba(0, 0, 0, 0.5);
    }

    @media (max-width: 1299px) {
      display: block;
    }
  }
}

.nav {
  &__list {
    display: flex;
  }

  &__item {
    padding: 0 20px;
  }

  &__link {
    color: $dark;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      color: var(--primary);
      outline: none;
    }

    &.active {
      color: var(--primary);
    }
  }

  @media screen and (max-width: 1600px) {
    &__link {
      font-size: 15px;
    }

    &__item {
      padding: 0 13px;
    }
  }

  @media (max-width: 1299px) {
    &__list {
      flex-direction: column;
      justify-content: center;
    }

    &__item {
      padding: 10px 0;
      text-align: center;
    }

    &__link {
      font-size: 20px;
      color: #111;
      transition: color 0.5s cubic-bezier(0.26, 1.04, 0.54, 1), background 0.6s cubic-bezier(0.77, 0, 0.175, 1);
    }
  }

  @media (max-width: 768px) {
    &__link {
      font-size: 17px;
    }

    &__item {
      padding: 7px 0;
    }
  }
}

.nav__dropdown {
  @media (max-width: 1299px) {
    background-color: #fff;
    transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
    position: fixed;
    bottom: 0;
    right: 0;
    height: 100vh;
    transform: translate3d(100%, 0, 0);
    width: 100%;
    z-index: 120;
    padding: 70px 45px 20px;
    overflow-y: auto;
  }

  &--active {
    transform: translateZ(0);
    transition: transform 1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  }

  @media (max-width: 768px) {
    padding-left: 25px;
    padding-right: 25px;
  }
}
