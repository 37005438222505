.inner-block {
  aside,
  .aside-outside {
    width: 325px;
  }

  &__content {
    width: calc(100% - 350px);
  }

  @media screen and (max-width: 1200px) {
    .aside-outside {
      width: 100%;
      margin-bottom: 25px;
    }

    &__content {
      width: 100%;
    }
  }
}
