.gallery-img {
  width: 130px;
  margin-bottom: 7px;
  height: 100px;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 12px;

  .gallery-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translateY(-50%) translateX(-50%);
    transition: 0.1s ease opacity;
    z-index: 100;
    pointer-events: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .gallery-preview {
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      background: rgba(59, 68, 89, 0.6);
      opacity: 0;
      transition: 0.3s ease;
    }
  }

  &:hover {
    .gallery-icon {
      opacity: 1;
    }

    .gallery-preview {
      &::before {
        opacity: 1;
      }
    }
  }

  @media (max-width: 1600px) and (min-width: 1501px) {
    width: calc((100% / 3) - 12px);
  }

  @media (max-width: 768px) {
    width: calc((100% / 3) - 12px);
  }

  @media (max-width: 480px) {
    width: calc((100% / 2) - 5px);
    margin-right: 0;
  }
}

.gallery-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;

  @media (max-width: 1200px) {
    padding-top: 10px;
  }

  @media (max-width: 480px) {
    justify-content: space-between;
  }
}
