.mb-none {
  margin-bottom: 0;
}

// название тайтлов
.title-bold {
  font-family: "Montserrat-Bold", sans-serif;
  font-weight: normal;
}

.title-semi-bold {
  font-family: "Montserrat-SemiBold", sans-serif;
  font-weight: normal;
}

.title-inner-bold {
  font-family: "Inter-SemiBold", sans-serif;
  font-weight: normal;
}

.title-inter-medium {
  font-family: "Inter-Medium", sans-serif;
  font-weight: normal;
}

.text-regular {
  font-family: "Inter-Regular", sans-serif;
}

.text-upper {
  text-transform: uppercase;
}

//цвета
.text-black,
.black {
  color: #000;
}

.dark {
  color: #2d3033;
}

.gray-dark {
  color: #3b4459;
}

.text-red {
  color: #cb0a0a;
}

.text-light {
  color: var(--gray);
}

.text-green {
  color: var(--green);
}

.text-blue {
  color: var(--primary);
}

//оступы
.title-mb {
  margin-bottom: 20px;
}

.content {
  padding-top: 25px;
  padding-bottom: 30px;
}

.txt-info {
  @media screen and (max-width: 992px) {
    font-size: 14px;
  }
}

/* размер шрифтов */
.display-large {
  font-size: 30px;
  margin-bottom: 17px;

  @media (max-width: 1660px) {
    font-size: 27px;
  }

  @media (max-width: 1360px) {
    font-size: 25px;
    margin-bottom: 14px;
  }

  @media (max-width: 767px) {
    font-size: 21px;
    margin-bottom: 10px;
  }
}

.display-average {
  font-size: 24px;

  @media screen and (max-width: 1200px) {
    font-size: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
}

h4.min,
.min {
  font-size: 14px;
}

h2 {
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.list-style {
  li::before {
    content: "\2022";
    color: var(--green);
    font-weight: bold;
    width: 8px;
    margin-right: 9px;
  }
}

.relative {
  position: relative;
}
