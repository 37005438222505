.content-wrap {
  padding: 17px 0;

  .content-text {
    max-width: 810px;
  }

  @media (max-width: 1360px) {
    .content-text {
      font-size: 15px;
      max-width: calc(100% - 350px);
    }

    img {
      width: 340px;
    }
  }

  @media (max-width: 992px) {
    .content-text {
      font-size: 14px;
    }

    h1 {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 766px) {
    padding-top: 30px;

    .content-text {
      width: 100%;
      max-width: 100%;
    }

    h1 {
      margin-bottom: 8px;
    }

    img {
      margin-left: auto;
    }
  }

  @media screen and (max-width: 480px) {
    img {
      width: 220px;
    }
  }
}

.our-mission {
  padding: 60px 0;

  &__title {
    font-size: 18px;
    color: #2d3033;
    max-width: 806px;
    margin: 0 auto 80px;
    text-align: center;
  }

  &__content {
    width: calc(100% - 200px);
  }

  @media (max-width: 1366px) {
    &__content {
      width: 100%;
      padding-top: 20px;
    }

    &__title {
      font-size: 17px;
      margin-bottom: 55px;
      line-height: 1.4;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 40px 0;

    &__title {
      font-size: 15px;
      max-width: 700px;
      margin-bottom: 35px;
    }

    &__content {
      padding-top: 15px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 25px;

    &__content {
      padding-top: 15px;
    }
  }

  @media (max-width: 576px) {
    &__title {
      font-family: "Inter-Medium", sans-serif;
      font-size: 14px;
    }
  }
}

.our-teams {
  padding-top: 20px;
  padding-bottom: 80px;

  &__title {
    color: #68727a;
    font-size: 18px;
    border-bottom: 1px solid #d2d8e4;
    padding-bottom: 15px;

    @media screen and (max-width: 1200px) {
      font-size: 16px;
      padding-bottom: 10px;
    }
  }

  .card-employee-wrapper {
    padding-top: 24px;
    margin-left: -8px;
    margin-right: -8px;

    .card-employee {
      width: calc(100% / 4);

      @media screen and (max-width: 1200px) {
        width: calc(100% / 3);
      }

      @media screen and (max-width: 768px) {
        width: calc(100% / 2);
      }

      @media screen and (max-width: 480px) {
        width: calc(100%);
      }
    }

    @media screen and (max-width: 992px) {
      padding-top: 20px;
    }

    @media screen and (max-width: 480px) {
      padding-top: 15px;
    }
  }

  &__group {
    padding-bottom: 30px;

    &--border {
      border-top: 1px solid #d2d8e4;
    }

    &:last-child {
      padding-bottom: 0;
    }

    @media (max-width: 1360px) {
      padding-bottom: 10px;
    }
  }

  @media screen and (max-width: 1200px) {
    padding-bottom: 30px;
  }
}
