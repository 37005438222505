.body-found {
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.not-found {
  max-width: 890px;
  margin: 0 auto;
  text-align: center;

  h1 {
    color: var(--primary);
    font-size: 180px;
    line-height: 1.3;
  }

  &__sub {
    font-size: 40px;
    color: #242424;
    line-height: 1.3;
  }

  .button {
    width: 350px;
    padding: 15px;
  }

  @media screen and (max-width: 1360px) {
    &__sub {
      font-size: 35px;
    }

    h1 {
      font-size: 150px;
    }

    .button {
      padding: 13px;
      width: 290px;
    }
  }

  @media screen and (max-width: 992px) {
    margin: 12%;

    &__sub {
      font-size: 27px;
    }

    h1 {
      font-size: 120px;
    }

    .button {
      padding: 10px;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 576px) {
    &__sub {
      font-size: 16px;
    }

    h1 {
      font-size: 70px;
    }

    .button {
      font-size: 13px;
      padding: 8px 20px;
      width: auto;
    }
  }
}

@media screen and (max-height: 900px) and (min-width: 992px) {
  .not-found {
    padding-top: 25px;
    padding-bottom: 25px;

    &__sub {
      font-size: 26px;
    }

    h1 {
      font-size: 120px;
    }

    .button {
      padding: 10px;
      font-size: 14px;
    }
  }
}
