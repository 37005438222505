.bg-card-mt {
  margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.block-card {
  &__head {
    padding: 20px 32px;

    p {
      font-size: 12px;
      margin-left: 20px;
    }

    @media (max-width: 1200px) {
      padding: 15px 20px;

      h3 {
        font-size: 17px;
      }
    }

    @media (max-width: 576px) {
      padding: 13px 15px;

      h3 {
        font-size: 15px;
      }

      p {
        width: calc(100% - 78px);
      }
    }
  }

  &__foot {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 13px 32px;

    @media (max-width: 1200px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: 576px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.add-subject {
  &__head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #ebeef2;
    padding: 5px 0 12px;

    h4 {
      margin-right: 16px;
      color: var(--gray);

      @media screen and (max-width: 768px) {
        font-size: 13px;
      }
    }
  }
}

.list-subject {
  &__item {
    padding: 15px 0;
    border-bottom: 1px solid #ebeef2;
    font-size: 14px;
  }

  .button-action {
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }

  &__mark {
    color: #275adb;
    font-family: "Inter-SemiBold", sans-serif;
  }

  &__name {
    color: #3b4459;
    width: 480px;
  }

  &__mark,
  &__name {
    margin-right: 15px;
  }

  .form {
    &__line {
      align-items: flex-start;
    }

    &__item {
      margin-right: 24px;
    }

    &__item--max {
      width: 350px;
    }

    &__item--min {
      width: 80px;
    }

    &__item--middle {
      width: 180px;
    }

    input {
      padding: 7px 12px;
    }

    .css-tpfbus-control,
    .css-evmmvm-control {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 35px;
    }

    .button {
      width: 160px;
      padding: 6px;
    }
  }

  @media screen and (max-width: 992px) {
    &__name {
      width: 370px;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    &__name {
      width: 100%;
    }

    .button-action {
      margin: 0 5px;
    }

    .form {
      width: 100%;

      &__line {
        justify-content: space-between;
      }

      .form__item {
        margin-right: 0;
        margin-bottom: 10px;
      }

      &__item--max {
        width: 100%;
      }

      &__item--min,
      &__item--middle,
      .button {
        width: 49%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .form {
      &__item--min,
      &__item--middle,
      .button {
        width: 100%;
      }
    }
  }
}
