.indent {
  padding: 60px 0 100px 0;

  @media (max-width: 1366px) {
    padding: 45px 0 70px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 35px 0 35px 0;
  }
}

.title-mb {
  margin-bottom: 50px;

  @media (max-width: 1366px) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}
