.create-block {
  padding: 40px;

  h1 {
    margin-bottom: 24px;
  }

  .form {
    max-width: 900px;

    legend {
      margin-bottom: 10px;
    }

    &__item {
      &--mr {
        margin-right: 16px;
      }
    }

    &__item--w {
      width: 180px;
    }

    .button {
      width: 300px;
    }

    .button-experience {
      width: 225px;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .icon {
      position: absolute;
      right: 20px;
      top: 13px;
      color: var(--gray);
      font-size: 14px;
    }

    &__bg {
      background: #f4f6fa;
      border: 1px solid #d2d8e4;
      border-radius: 4px;
      padding: 24px;
      position: relative;
    }
  }

  .wrap-media {
    h3 {
      font-size: 16px;
      margin-bottom: 7px;
      line-height: 18px;
      color: #2d3033;

      &.mb {
        margin-bottom: 16px;
      }
    }

    p {
      margin-bottom: 10px;
    }

    &--border {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 15px;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 30px 25px;

    h1 {
      margin-bottom: 17px;
    }
  }

  @media screen and (max-width: 992px) {
    .form {
      .item {
        margin-bottom: 17px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 20px;

    .form {
      &__item {
        margin-bottom: 13px;

        &--mr {
          margin-right: 0;
        }
      }

      .form__line {
        .form__item {
          width: 100%;
        }
      }

      .radio-label {
        width: 100%;
        padding: 5px;

        span {
          white-space: nowrap;
        }
      }
    }

    p {
      font-size: 13px;
      line-height: 1.3;
      margin-bottom: 0;
    }
  }

  @media (max-width: 576px) {
    .form {
      .button {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.work-experience {
  label {
    color: #3f4448;
  }

  .form__inline {
    justify-content: space-between;
    align-items: flex-end;

    .form__group {
      align-items: flex-start;
    }

    .form__item {
      width: 180px;
      margin-right: 10px;

      &--width {
        width: 260px;
        margin-bottom: 0;
      }
    }
  }

  &__block {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .work-experience {
    .form__bg {
      padding: 20px 10px;
    }
  }
}

@media screen and (max-width: 767px) {
  .work-experience {
    .form__group-wrap {
      width: 100%;
    }

    .button-action {
      margin-left: auto;
    }
  }

  .work-experience .form__inline .form__item {
    width: 100%;
  }
}

