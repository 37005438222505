.button {
  border-radius: 4px;
  display: inline-block;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease;
  text-align: center;
  border: none;
  color: #fff;
  background: var(--bg, transparent);

  span.title {
    padding-left: 12px;
  }

  &:hover {
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    background: #f0f0f0;
    color: #e0e0e0;
  }
}

.button-gradient-primary {
  --bg: var(--primary-gradient);
}

.button-green-gradient {
  --bg: var(--green-gradient);
}

.button-link {
  border: 2px solid #d2d8e4;
  background-color: transparent;
  padding: 8px 24px;
  color: #68727a;

  span {
    padding-left: 10px;
    vertical-align: middle;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(168, 173, 181, 0.25);
    outline: none;
  }

  &:hover {
    color: #68727a;
  }
}

.button-small {
  font-size: 14px;
}

.button-big {
  font-size: 18px;
}

.button-bold {
  font-family: "Inter-SemiBold", sans-serif;
}

.button-upper {
  text-transform: uppercase;
}

.button-green {
  background: var(--green);
}

.button-indent {
  padding: 10px 25px;
}

.button-action {
  border-radius: 4px;
  border: none;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: width 0.4s ease;

  @media screen and (max-width: 768px) {
    width: 35px;
    height: 33px;

    svg {
      width: 13px;
    }
  }
}

.button-action,
.btn-option {
  &--gray {
    background: #f4f6fa;
  }

  &--gray-dark {
    background: #99aac3;
  }

  &--red {
    background: linear-gradient(0deg, #ff4141, #ff4141), #ee3636;
  }

  &--green {
    background: var(--green-gradient);
  }

  &--yellow {
    background: #ffbd13;
  }

  &--orange {
    background: linear-gradient(0deg, #ff5f2d, #ff5f2d), #ef801a;
  }

  &--purple {
    background: #9b51e0;
  }
}

.btn-options {
  &--mb {
    margin-bottom: 24px;
  }

  svg {
    margin-right: 19px;
  }

  .btn-option {
    padding: 14px 24px;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1200px) {
    &--mb {
      margin-bottom: 17px;
    }

    .btn-option {
      padding: 12px 18px;
      font-size: 15px;
    }

    svg {
      margin-right: 13px;
    }
  }

  @media screen and (max-width: 992px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .btn-option {
      width: 49.5%;
      margin-right: 0;
      margin-bottom: 7px;
      padding: 10px 5px;
    }
  }

  @media (max-width: 576px) {
    .btn-option {
      width: 100%;
    }
  }
}

.button-transparent {
  background-color: transparent;
  border: none;
  padding: 0;
}

.button-favorites {
  background: #effcfc;
  border: 1px solid #dadce3;
  color: #68727a;

  svg {
    margin-right: 10px;
  }

  &:hover {
    color: #68727a;
  }

  &.active {
    background: #ffbd13;
    color: #fff;
    border-color: #ffbd13;

    svg path {
      fill: #fff;
    }
  }
}

.button--modal {
  width: 35px;
  height: 29px;
}

.filter-button {
  background: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border: none;
  width: 48px;
  height: 48px;
}

.button-create {
  padding: 10px 25px;

  @media screen and (max-width: 992px) {
    font-size: 14px;
    padding: 8px 20px;
  }
}
