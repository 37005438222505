.filter-block {
  padding: 24px;

  .form {
    .form__item {
      margin-bottom: 16px;
    }

    .button {
      width: 100%;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 15px;
  }

  @media (max-width: 767px) {
    .form {
      .form__item,
      .form__group {
        margin-bottom: 10px;
      }
    }
  }
}

.aside-outside--secondary {
  .filter-button {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    .filter-block {
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px);
      position: absolute;
      width: 100%;
      z-index: 1000;

      &.active {
        visibility: visible;
        opacity: 1;
        transform: translateY(10px);
        transition: 0.2s ease-in-out;
      }

      .form {
        .form__item {
          width: 49%;

          &--category {
            width: 100%;
          }
        }

        .button {
          width: 49%;
        }

        &__group {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;

          &--flex-end {
            align-items: flex-end;

            .form__item:nth-last-child(-n+2) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .filter-button {
      display: block;
    }
  }

  @media (max-width: 767px) {
    .filter-block {
      .form {
        .form__item,
        .button {
          width: 100%;
        }

        &__group {
          &--flex-end {
            .form__item:nth-last-child(-n+2) {
              margin-bottom: 13px;
            }
          }
        }
      }
    }
  }
}
