.avatar {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  overflow: hidden;
  object-fit: cover;
}

.user {
  display: flex;
  align-items: center;
  font-size: 12px;

  &__information {
    text-align: right;
    padding-right: 8px;
  }

  &__university {
    color: var(--primary-gradient);
  }

  &__name {
    color: #444;
  }

  @media screen and (max-width: 992px) {
    .user__information {
      padding-right: 0;
      order: 1;
    }

    img {
      margin-right: 8px;
    }
  }
}
