.notification-container {
  background: rgba(243, 243, 248, 0.3);
  border: 1px solid #d2d8e4;
  border-radius: 6px;
  padding: 35px;

  .form {
    legend {
      margin-bottom: 24px;
    }

    input {
      padding: 0;
    }

    .form__item {
      width: auto;

      label {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1360px) {
    padding: 25px 30px;
  }

  @media screen and (max-width: 992px) {
    padding: 20px;

    .form {
      .form__item {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
