.work + .work {
  margin-top: 15px;
}

.work__experience-count,
.work__name-post {
  margin-bottom: 8px;
}

.work__name-organization {
  margin-bottom: 16px;
}

.work__dots {
  margin-right: 17px;
}

.work__group {
  flex: 1;
}

@media screen and (max-width: 992px) {
  .work__dots {
    margin-right: 10px;
  }
}
